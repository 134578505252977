.form-container {
    min-height: 70vh;
}
.user-form {
    max-width: 520px;
    padding: 48px 24px;
    
}
.redir-link:hover {
    background-color: #eee;
    text-decoration: none;
}